import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Contact from '../views/Contact'

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const StyledLink = styled.a`
  ${tw`font-bold text-green`}
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={2}>
      <Hero>
        <BigTitle>
          Hi, I'm <br /> Kyle Pfahler
        </BigTitle>
        <Subtitle>
          I'm a Cincinnati, Ohio based software developer. I work mainly in front-end technologies and UI/UX design
          <br /> <br />
          I'm currently writing code and designing features at{' '}
          <StyledLink href="https://www.crakn.net">CRäKN</StyledLink>
        </Subtitle>
      </Hero>

      <Contact offset={1}>
        <Inner>
          <Title>Hit Me Up!</Title>
          <ContactText>
            <a href="mailto:pfahlerk1@gmail.com">Shoot me an email</a> or find me on:{' '}
            <a href="https://www.github.com/failerk/">GitHub</a> &{' '}
            <a href="https://www.linkedin.com/in/kylepfahler/">LinkedIn</a>
          </ContactText>
        </Inner>
      </Contact>
    </Parallax>
  </>
)

export default Index
